import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Api from '../classes/ApiClass';

export default function PatientDetail() {

    const {id} = useParams();
    const [patient, setPatient] = React.useState(null);
    const api = new Api();

    useEffect(() => {
        api.getPatientDetail(id).then((data) => {
            setPatient(data);
            console.log(data);
        });
    }, []);

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='card'>
                            <div className='card-header'>
                                Hasta Bilgileri
                            </div>
                            <div className='card-body'>
                                <table className='table table-striped'>
                                    <tbody>
                                        <tr>
                                            <td><strong>İsim</strong></td>
                                            <td>{patient?.first_name}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Soyisim</strong></td>
                                            <td>{patient?.last_name}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Doğum Tarihi</strong></td>
                                            <td>{patient?.birth_date}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Telefon</strong></td>
                                            <td>{patient?.phone}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Eposta</strong></td>
                                            <td>{patient?.email}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Adres</strong></td>
                                            <td>{patient?.address}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <div className='card'>
                            <div className='card-header'>
                                Randevular
                            </div>
                            <div className='card-body' style={{height:283,overflow:"auto"}}>
                                <table className='table table-striped'>
                                    <tbody>
                                        <tr>
                                            <td className='text-center'>
                                                <strong>Randevu bulunmamakta.</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-8'>
                        <div className='card'>
                            <div className='card-header'>
                                Mesajlaşma
                            </div>
                            <div className='card-body'>
                                <table className='table table-striped' style={{height:290,overflow:"auto"}}>
                                    <tbody>
                                        <tr>
                                            <td className='text-center'>
                                                <strong>Mesajlaşma bulunmamakta.</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <form>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" placeholder="Mesajınızı giriniz." aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                        <button className="btn btn-primary" type="button" id="button-addon2">Gönder</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='card'>
                            <div className='card-header'>
                                Dökümanlar
                            </div>
                            <div className='card-body' style={{height:380,overflow:"auto"}}>
                                <table className='table table-striped'>
                                    <tbody>
                                        <tr>
                                            <td className='text-center'>
                                                <strong>Döküman bulunmamakta.</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
