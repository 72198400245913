import React from 'react'
import Api from '../classes/ApiClass';
import { useParams } from 'react-router-dom';


export default function AddPatient() {

    const {id} = useParams();
    const [patient, setPatient] = React.useState(null);

    React.useEffect(() => {
        if (id) {
            const api = new Api();
            api.getPatient(id).then((data) => {
                setPatient(data);
                console.log(data);
            });
        }
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const api = new Api();
        const data = new FormData(e.target);
        const value = Object.fromEntries(data.entries());
        const body = api.exceptEmpty(value);
        console.log(body);
        if (id) {
            api.updatePatient(id, body).then((data) => {
                window.location.href = "/hasta";
            });
            return;
        }
        api.createPatient(body).then((data) => {
            window.location.href = "/hasta";
        });
    }

  return (
    <div className='container-fluid'>
        <div className='row justify-content-center'>
            <div className='col-md-6'>
                <div className='card'>
                    <div className='card-header'>Hasta Ekle</div>
                    <div className='card-body'>
                        <form className='mt-3' onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">İsim*</label>
                        <input name="first_name" value={patient?.first_name} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">Soyisim*</label>
                        <input name="last_name" value={patient?.last_name} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">Eposta*</label>
                        <input name="email" value={patient?.email}  type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">Telefon</label>
                        <input name="phone" value={patient?.phone} type="tel" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">Doğum Tarihi</label>
                        <input name="birth_date" value={patient?.birth_date} type="date" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    </div>
                    {/* Cinsiyet*/}
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">Cinsiyet</label>
                        <select name="gender" className="form-select" aria-label="Default select example">
                            <option value="1" selected={patient?.gender === "1" ? true : false}>Kadın</option>
                            <option value="2" selected={patient?.gender === "2" ? true : false}>Erkek</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">Boy</label>
                        <input name="height" value={patient?.height} type="number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">Kilo</label>
                        <input name="weight" value={patient?.weight} type="number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">Adres</label>
                        <textarea name="address" className="form-control" id="exampleFormControlTextarea1" rows="3" value={patient?.address}/>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button type="submit" className="btn btn-primary">Kaydet</button>
                    </div>
                </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
