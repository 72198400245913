import React from 'react'
import DataTable from 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.css';
import $ from 'jquery';
import Api from '../classes/ApiClass';

export default function Patients() {

    const [patients, setPatients] = React.useState([]);
    const api = new Api();

    React.useEffect(() => {
        $(document).ready(function () {
            api.getPatient().then((data) => {
                // setPatients(data);
            }).then(() => {
                setTimeout(() => {
                    $('#example').DataTable();
                }, 500);
            });
        });
    }, []);

    const handleDelete = (e) => {
        e.preventDefault();
        const id = e.target.getAttribute("data-id");
        window.confirm("Hasta ile ilgili tüm veriler kalıcı olarak silinecek. Onaylıyor musunuz?") && api.deletePatient(id).then((data) => {
            window.location.href = "/hasta";
        });
    }

  return (
    <>
    <div className="container-fluid">
        <div className='row'>
            <div className='card col-md-12'>
                <div className='card-header d-flex justify-content-between align-items-center'>
                    Hasta Listesi
                    <a href="/hasta/ekle" className="btn btn-primary btn-sm">Hasta Ekle <i className='bx bx-plus-medical' ></i></a>
                </div>
                <div className='card-body pt-3'>
                    <table id="example" className="display" style={{width: "100%"}}>
                        <thead>
                            <tr>
                                <th>İsim</th>
                                <th>Soyisim</th>
                                <th>Doğum Tarihi</th>
                                <th>Telefon</th>
                                <th>Eposta</th>
                                <th>Seçenekler</th>
                            </tr>
                        </thead>
                        <tbody>
                            {patients?.map((patient, index) => (
                                <tr key={index}>
                                    <td>{patient.first_name}</td>
                                    <td>{patient.last_name}</td>
                                    <td>{patient.birth_date}</td>
                                    <td>{patient.phone}</td>
                                    <td>{patient.email.substr(0,14)}...</td>
                                    <td>
                                        <a href={"/hasta/detay/"+patient.id} className="btn btn-primary btn-sm m-1"><i className='bx bx-search-alt-2' ></i></a>
                                        <a href={"/hasta/duzenle/"+patient.id} className="btn btn-warning btn-sm m-1"><i className='bx bx-edit' ></i></a>
                                        <a data-id={patient.id} onClick={handleDelete} className="btn btn-danger btn-sm m-1"><i className='bx bx-trash' ></i></a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
