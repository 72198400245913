import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/layout";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Patients from "./pages/Patients";
import AddPatient from "./pages/AddPatient";
import Categories from "./pages/Categories";
import Api from "./classes/ApiClass";
import config from './config';
import PatientDetail from "./pages/PatientDetail";
import Messages from "./pages/Messages";


function App() {

    const api = new Api();
    const [serverStatus, setServerStatus] = useState(null);


    useEffect(() => {
        api.healthCheck().then((data) => {
            setServerStatus(data);
        });
    }, []);

      if (serverStatus === null) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }

    if (serverStatus === false) {

      setTimeout(() => {
        window.location.reload();
      }, 5000);

  return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
          <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading">Sunucu Bağlantısı Başarısız!</h4>
            <p>Sunucu bağlantısı başarısız. 5 saniye içinde tekrar denenecek.<br/>Lütfen sunucu bağlantınızı kontrol edin.</p>
            <hr />
            <p className="mb-0">Sunucu adresi: {config.api}</p>
          </div>
      </div>
    )
  }

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Dashboard title="Pano"/>} />
        <Route path="/mesajlar" element={<Messages/>} />
        <Route path="/hasta" element={<Patients/>} />
        <Route path="/hasta/ekle" element={<AddPatient/>} />
        <Route path="/hasta/detay/:id" element={<PatientDetail/>} />
        <Route path="/hasta/duzenle/:id" element={<AddPatient/>} />
        <Route path="/blog/kategoriler" element={<Categories/>} />
      </Routes>
    </Layout>
  );
}

export default App;
