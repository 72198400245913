import React from 'react'

export default function Sidebar() {
  return (
    <aside id="sidebar" className="sidebar">
    <ul className="sidebar-nav" id="sidebar-nav">
      <li className="nav-item">
        <a className="nav-link collapsed" href="/">
          <i className="bi bi-grid" />
          <span>Pano</span>
        </a>
      </li>{/* End Dashboard Nav */}
      <li className="nav-item">
        <a className="nav-link collapsed" href="/mesajlar">
          <i className="bi bi-envelope" />
          <span>Mesajlar</span>
        </a>
      </li>{/* End Dashboard Nav */}
      <li className="nav-item">
        <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
          <i className="bi bi-menu-button-wide" /><span>Hastalar</span><i className="bi bi-chevron-down ms-auto" />
        </a>
        <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
          <li>
            <a href="/hasta">
              <i className="bi bi-circle" /><span>Hasta Listesi</span>
            </a>
          </li>
          <li>
            <a href="/hasta/ekle">
              <i className="bi bi-circle" /><span>Hasta Ekle</span>
            </a>
          </li>
        </ul>
      </li>{/* End Components Nav */}
      <li className="nav-item">
        <a className="nav-link collapsed" data-bs-target="#forms-nav" data-bs-toggle="collapse" href="#">
          <i className="bi bi-journal-text" /><span>Blog</span><i className="bi bi-chevron-down ms-auto" />
        </a>
        <ul id="forms-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
          <li>
            <a href="/blog/kategoriler">
              <i className="bi bi-circle" /><span>Kategori Listesi</span>
            </a>
          </li>
          <li>
            <a href="forms-editors.html">
              <i className="bi bi-circle" /><span>Yazılar</span>
            </a>
          </li>
          <li>
            <a href="forms-validation.html">
              <i className="bi bi-circle" /><span>Yazı Ekle</span>
            </a>
          </li>
        </ul>
      </li>{/* End Forms Nav */}
      <li className="nav-item">
        <a className="nav-link collapsed" href="users-profile.html">
          <i className='bx bx-images' ></i>
          <span>Galeri</span>
        </a>
      </li>{/* End Profile Page Nav */}
      <li className="nav-item">
        <a className="nav-link collapsed" href="users-profile.html">
          <i className='bx bxs-user-badge' ></i>
          <span>Profil</span>
        </a>
      </li>{/* End Profile Page Nav */}
      <li className="nav-item">
        <a className="nav-link collapsed" href="users-profile.html">
          <i className='bx bxs-user-account' ></i>
          <span>Kullanıcı Ayarları</span>
        </a>
      </li>{/* End Profile Page Nav */}
      <li className="nav-item">
        <a className="nav-link collapsed" href="users-profile.html">
          <i className='bx bx-spreadsheet' ></i>
          <span>Sayfa Yönetimi</span>
        </a>
      </li>{/* End Profile Page Nav */}
      <li className="nav-item">
        <a className="nav-link collapsed" href="users-profile.html">
          <i className='bx bx-cog' ></i>
          <span>Sistem Ayarları</span>
        </a>
      </li>{/* End Profile Page Nav */}

    </ul>
  </aside>
  )
}
