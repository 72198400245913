import React, { useEffect } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

import 'react-big-calendar/lib/css/react-big-calendar.css';


const localizer = momentLocalizer(moment)


export default function Dashboard(props) {


    const myEventsList = [
        {
            title: 'All Day Event very long title',
            allDay: true,
            start: new Date(2024, 3, 0),
            end: new Date(2024, 3, 1),
        },
        {
            title: 'Long Event',
            start: new Date(2024, 3, 7),
            end: new Date(2024, 3, 10),
        },

        {
            title: 'DTS STARTS',
            start: new Date(2024, 2, 13, 0, 0, 0),
            end: new Date(2024, 2, 20, 0, 0, 0),
        },

        {
            title: 'DTS ENDS',
            start: new Date(2024, 10, 6, 0, 0, 0),
            end: new Date(2024, 10, 13, 0, 0, 0),
        },

        {
            title: 'Some Event',
            start: new Date(2024, 3, 9, 0, 0, 0),
            end: new Date(2024, 3, 9, 0, 0, 0),
        },
        {
            title: 'Conference',
            start: new Date(2024, 3, 11),
            end: new Date(2024, 3, 13),
            desc: 'Big conference for important people',
        },
        {
            title: 'Meeting',
            start: new Date(2024, 3, 12, 10, 30, 0, 0),
            end: new Date(2024, 3, 12, 12, 30, 0, 0),
            desc: 'Pre-meeting meeting, to prepare for the meeting',
        },
        {
            title: 'Lunch',
            start: new Date(2024, 3, 12, 12, 0, 0, 0),
            end: new Date(2024, 3, 12, 13, 0, 0, 0),
            desc: 'Power lunch',
        },
        {
            title: 'Meeting',
            start: new Date(2024, 3, 12, 14, 0, 0, 0),
            end: new Date(2024, 3, 12, 15, 0, 0, 0),
        },
    ];

    return (
        <>
            <div className="pagetitle" style={{marginBottom:"20px"}}>
                <h1>{props.title}</h1>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <div className="card">
                        <div className="card-header"><i className='bx bx-calendar bx-md'></i> Toplam Randevu</div>
                        <div className="card-body">
                            <h1>0</h1>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card">
                        <div className="card-header"><i className='bx bx-plus-medical bx-md' ></i> Toplam Hasta</div>
                        <div className="card-body">
                            <h1>0</h1>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card">
                        <div className="card-header"><i className='bx bx-message-rounded-dots bx-md' ></i> Toplam Mesaj</div>
                        <div className="card-body">
                            <h1>0</h1>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card">
                        <div className="card-header"><i className='bx bx-street-view  bx-md' ></i> Toplam Görüntülenme</div>
                        <div className="card-body">
                            <h1>0</h1>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='card'>
                        <div className='card-header d-flex justify-content-between slign-items-center'>
                            <span>Randevu Takvimi</span>
                            <button className='btn btn-primary btn-sm '>Randevu Ekle <i className='bx bx-plus-circle'></i></button>
                        </div>
                        <div className='card-body'>
                            <Calendar
                                localizer={localizer}
                                startAccessor="start"
                                events={myEventsList}
                                endAccessor="end"
                                style={{ height: 500 }}
                                onSelectEvent={event => alert(event.desc)}
                                onSelectDate={date => alert(date)}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='card'>
                        <div className='card-header d-flex justify-content-between slign-items-center'>
                            <span>Randevu Talepleri</span>
                        </div>
                        <div className='card-body' style={{height:528,overflow:'auto'}}>
                            <div className='row'>
                                {
                                    [1, 2, 3, 4, 5, 6].map((item, index) => {
                                        return (
                                            <div key={index} className='col-md-6'>
                                                <div className='card'>
                                                    <div className='card-body pt-3'>
                                                        <span>Ali Veli</span><br/>
                                                        <span><i className='bx bxs-phone' ></i> 0555 555 55 55</span><br/>
                                                        <span><i className='bx bxs-calendar-alt' ></i> 24.12.2024</span><br/>
                                                        <span><i className='bx bxs-time' ></i> 12:00</span>
                                                    </div>
                                                    <div className='card-footer d-flex justify-content-end'>
                                                        <button className='btn btn-primary btn-sm  m-1'>Randevu Al <i className='bx bx-plus-circle'></i></button>
                                                        <button className='btn btn-danger  m-1'>Reddet <i className='bx bx-minus-circle'></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
