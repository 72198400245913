
import axios from 'axios';
const config = require("../config");

class Api {

    exceptEmpty(json) {
        Object.keys(json).forEach((key) => (json[key] == null || json[key] == '') && delete json[key]);
        return json;
    }

    async get(url = '', params = {}) {
        try {
            const response = await axios.get(config.api + url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                method: 'GET',
                redirect: 'follow',
                params: this.exceptEmpty(params)
            });
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async post(url = '', params = {}) {
        try {
            const response = await axios.post(config.api + url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                params: this.exceptEmpty(params)
            });
            return response.data;
        } catch (error) {
            return error.response.data.message;
        }
    }

    async put(url = '', params = {}) {
        try {
            const response = await axios.put(config.api + url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                params: this.exceptEmpty(params)
            });
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async delete(url = '', params = {}) {
        try {
            const response = await axios.delete(config.api + url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                params: this.exceptEmpty(params)
            });
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async healthCheck() {
        const health = await this.get();
        return health.status === 'up' ? true : false;
    } 

    async createPatient(body) {
        return this.post("users", body);
    }

    async updatePatient(id, body) {
        return this.put("users/" + id, body);
    }

    async getPatient(id = null) {
        if (id) {
            return this.get("users/" + id);
        }
        return this.get("users?role=user");
    }

    async getPatientDetail(id = null) {
        return this.get("users/" + id + "?detail=true");
    }

    async deletePatient(id) {
        return this.delete("users/" + id);
    }

    async createCategory(body) {
        return this.post("categories", body);
    }

    async getCategory(id = '') {
        return this.get("categories/" + id);
    }
}

export default Api;