import React from 'react'
import DataTable from 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.css';
import $ from 'jquery';
import ApiClass from '../classes/ApiClass';
import config from '../config';


export default function Categories() {

    const [categories, setCategories] = React.useState(null);
    const [image, setImage] = React.useState(null);

    React.useEffect(() => {
        const api = new ApiClass();
        api.getCategory().then((data) => {
            setCategories(data);
        });

        $(document).ready(function () {
            $('#example').DataTable();
        });
    }, []);

    const getBase64 = (e) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = async () => {
            setImage(reader.result);
        };
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const value = Object.fromEntries(data.entries());
        if (image) {
            value.image = image;
        }
        console.log(value);
    }

    return (
    <>
    <div className="container-fluid">
        <div className='row'>
            <div className='card col-md-8'>
                <div className='card-header d-flex justify-content-between align-items-center'>
                    Kategori Listesi
                </div>
                <div className='card-body pt-3'>
                    <table id="example" className="display" style={{width: "100%"}}>
                        <thead>
                            <tr>
                                <th>İsim</th>
                                <th>Soyisim</th>
                                <th>Doğum Tarihi</th>
                                <th>Telefon</th>
                                <th>Eposta</th>
                                <th>Seçenekler</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Tiger</td>
                                <td>Nixon</td>
                                <td>System Architect</td>
                                <td>Edinburgh</td>
                                <td>61</td>
                                <td>
                                    <a href="#" className="btn btn-primary btn-sm m-1"><i className='bx bx-search-alt-2' ></i></a>
                                    <a href="#" className="btn btn-warning btn-sm m-1"><i className='bx bx-edit' ></i></a>
                                    <a href="#" className="btn btn-danger btn-sm m-1"><i className='bx bx-trash' ></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='card'>
                    <div className='card-header d-flex justify-content-between align-items-center'>
                        Kategori Ekle
                    </div>
                    <div className='card-body pt-3'>
                        <form className='mt-3' onSubmit={handleFormSubmit}>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">İsim</label>
                                <input type="text" name='name' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">URL</label>
                                <input type="text" name="slug" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Görsel</label>
                                <input type="file" id="image" className="form-control" aria-describedby="emailHelp" onChange={getBase64}/>
                                {image ? (
                                    <>
                                    <img src={image} style={{maxWidth:'70%', maxHeight: '150px',marginTop:'20px'}} alt="" />
                                    <button onClick={()=>{setImage();}} className='btn btn-sm btn-danger' style={{position:"absolute",marginTop:"20px",marginLeft:"-45px"}}>İptal</button>
                                    </>
                                ):""}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Üst Kategori</label>
                                <select className="form-select" aria-label="Default select example" name="parent_id" defaultValue="">
                                    <option value="">Kategori Seçiniz</option>
                                    <option value="1">Erkek</option>
                                    <option value="2">Kadın</option>
                                </select>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <button type='submit' className="btn btn-primary">Kaydet</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
