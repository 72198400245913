import React from 'react'

export default function Messages() {

    const [selectedMessage, setSelectedMessage] = React.useState(null);

    const openChatScreen = (id) => {
        setSelectedMessage(id);
    }

  return (
    <>
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-4'>
                <div className='card'>
                    <div className='card-header'>
                        Mesajlar
                    </div>
                    <div className='card-body' style={{height:"78vh",overflow:"auto"}}>
                        <table className='table table-hover'>
                            <tbody>
                                <tr style={{cursor:"pointer"}} onClick={()=>{openChatScreen(2)}}>
                                    <td>
                                        <strong>Ali Veli</strong>
                                        <p className='text-muted' style={{fontSize:"14px"}}>Test Mesaj</p>
                                        <span className='text-muted' style={{fontStyle:"oblique",fontSize:"12px"}}>12:20 - 01.01.2020</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className='col-md-8'>
                {selectedMessage ? (
                    <div className='card'>
                    <div className='card-header d-flex justify-content-between align-items-center' onClick={()=>{setSelectedMessage()}}>
                        <strong>Ali Veli</strong>
                        <div className='btn btn-danger btn-sm'><i className='bi bi-x-lg'></i></div>
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='card'>
                                    <div className='card-body' style={{height:"64vh",overflow:"auto"}}>
                                        <table className='table table-hover'>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <strong>Ali Veli</strong>
                                                        <p className='text-muted' style={{fontSize:"14px"}}>
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.   
                                                        </p>
                                                        <span className='text-muted' style={{fontStyle:"oblique",fontSize:"12px"}}>12:20 - 01.01.2020</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{background:"#CDF5FD77"}}>
                                                        <strong>Siz</strong>
                                                        <p className='text-muted' style={{fontSize:"14px"}}>Test Mesaj</p>
                                                        <span className='text-muted' style={{fontStyle:"oblique",fontSize:"12px"}}>12:20 - 01.01.2020</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Ali Veli</strong>
                                                        <p className='text-muted' style={{fontSize:"14px"}}>Test Mesaj</p>
                                                        <span className='text-muted' style={{fontStyle:"oblique",fontSize:"12px"}}>12:20 - 01.01.2020</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Ali Veli</strong>
                                                        <p className='text-muted' style={{fontSize:"14px"}}>Test Mesaj</p>
                                                        <span className='text-muted' style={{fontStyle:"oblique",fontSize:"12px"}}>12:20 - 01.01.2020</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Ali Veli</strong>
                                                        <p className='text-muted' style={{fontSize:"14px"}}>Test Mesaj</p>
                                                        <span className='text-muted' style={{fontStyle:"oblique",fontSize:"12px"}}>12:20 - 01.01.2020</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='card-footer'>
                                        <div className='d-flex justify-content-end'>
                                            <textarea className="form-control form-control-sm" placeholder="Mesajınızı giriniz." aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                            <button className='btn btn-primary btn-sm'>Gönder</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                ):(
                    <div className='card'>
                        <div className='card-body d-flex justify-content-center align-items-center' style={{height:"84vh"}}>
                            <strong>Mesaj seçiniz</strong>
                        </div>
                    </div>
                    )
                    }
            </div>
        </div>
    </div>
    </>
  )
}
